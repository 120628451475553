.paymentMethod{
  &__wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 25px 15px 20px;
  }
  &__title {
    color: #3A3A3A;
    font-weight: 600;
    flex:2;
    font-size: 14px;
  }
  &__button {
    height: 30px;
    width: 75px;
    background-color: #E0004D;
    border: none;
    border-radius: 15px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    span {
      margin-right: 5px;
    }
  }
  &__modal {
    background-color: #FFFFFF;
    width: 320px;
    min-height: 210px;
    padding: 40px 10px 15px;
    border-radius: 3px;
    overflow: auto;
  }
  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__modalContent {
    padding: 0;
    list-style: none;
    height: 100%;
    margin: 0;
  }
  &__selectedImage {
    height: 30px;
    flex: 0
  }
  &__selectedName {
    flex: 2;
    padding-left:10px;
    font-size: 14px;
  }
  &__iconClose {
    position: absolute;
    top: 0;
    float: ro;
    text-align: right;
    display: flex;
    width: 100%;
    left: 0;
    float: right;
    justify-content: flex-end;
    font-size: 20px;
    padding: 12px 18px;
  }
}

.paymentList {
  display: flex;
  flex-flow: row wrap;
  height: 50px;
  align-items: center;
  padding: 0 5px;
  &:active{
    background-color: #E7F5FF;
  }
  &__selected {
    background-color: #E7F5FF;
    display: flex;
    flex-flow: row wrap;
    height: 50px;
    align-items: center;
    padding: 0 5px;
  }
  &__img{
    max-height: 30px;
    margin-right: 43px;
  }
  &__title{
    color: #333333;
    font-size: 14px;
  }
}
