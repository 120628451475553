.donation {
  &__wrapper {
    padding: 30px 15px 20px;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    background: #FFFFFF;
  }
  &__status {
    text-align: center;
    margin: 0;
    color: #4A4A4A;
    font-size: 18px;
    font-weight: 600;
  }
  &__image {
    object-fit: contain;
    margin: 30px 0;
    width: auto;
    height: auto;
    max-width: unset;
  }
  &__infoAmount {
    text-align: center;
    color: #4A4A4A;
    padding: 0 24px;
  }
}

.customDivider{
  background: #F5F5F5;
  height: 5px;
  max-width: 480px;
  margin: 0 auto;
}

.share {
  padding: 15px;
  max-width: 480px;
  background: white;
  margin: 0 auto;
  &__info{
    text-align: center;
    margin: 0 20px 15px;
    color: #4a4a4a;
  }
}

.shareButton {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.waShare {
  display: inline-block;
  font-weight: 700 !important;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  font-size: 14px !important;
  background-color: #25d366 !important;
  color: white !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  border-radius: 4px;
  height: 40px;
}

.fbShare {
  display: inline-block;
  font-weight: 700 !important;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  font-size: 14px !important;
  background-color: #3B5998 !important;
  color: white !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  border-radius: 4px;
  height: 40px;
}

.back{
  margin: 10px 0 16px;
  &__btn {
    width: 100%;
    border: 1px solid #50B058;
    padding: 10px;
    color: #50B058;
    font-size: 14px;
    background: #fff;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    &:focus{
      outline: none
    }
  }
}

.donate{
  margin: 10px 0 16px;
  &__btn {
    width: 100%;
    background-color:#D9336B;
    padding: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    border-color: transparent;
    &:focus{
      outline: none
    }
  }
}
