.instruction {
  padding: 30px 15px 20px;
  max-width: 480px;
  margin: 0 auto;
  background: #FFFFFF;
  &__title {
    font-size: 18px;
    color: #3a3a3a;
    text-align: center;
    margin:0;
    font-weight: 700;
  }
  &__subTitle {
    font-size: 14px;
    color: #4a4a4a;
    text-align: center;
    margin:0;
  }
  &__subTitle {
    font-size: 14px;
    color: #4a4a4a;
    text-align: center;
    margin:0;
  }
  &__amount {
    text-align: center;
    font-weight: 700;
    color: #4a4a4a;
    font-size: 27px;
    margin: 16px 0;
  }
  &__info {
    text-align: center;
    span {
      font-weight: bold;
    }
  }
  &__box {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #F9F9F9;
    border: 0.5px solid #E8E8E8;
    border-radius: 4px;
    padding: 8px 16px;
      img {
        width: 65px;
        height: 100%;
      }
      h3 {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        text-align: center;
        margin: 0.25em;
      }
      button{
        color: #00aeef;
        font-size: 12px;
        line-height: 17px;
        font-weight: bold;
        background: none;
        border: none;
      }
  }

  &__timebox {
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #FFFFFF;
    border: 0.5px solid #D8D8D8;
    border-radius: 4px;
    font-size: 12px;
  }
}

.customDivider{
  background: #F5F5F5;
  height: 10px;
  max-width: 480px;
  margin: 0 auto;
}

.guide {
  max-width: 480px;
  margin: 0 auto;
  background: #FFFFFF;
  padding: 16px 0 25px;;
  &__title {
    margin: 0;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }
  &__wrapper {
    border: 0.5px solid #E8E8E8;
    border-radius: 4px;
    margin: 0 15px;
    li {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 10px;
      color: #4a4a4a;
    }

  }
  &__bankInfo {
    padding-left: 25px;
    font-weight: 700;
    font-size: 12px;
  }
}

.shareContent {
  padding: 0 16px;
  text-align: center;
  font-weight: 700;
  color: #4A4A4A;
  font-size: 16px;
  max-width: 480px;
  margin: 0 auto;
  background: white;
  padding-top: 16px;

  p {
    margin-top: 0;
  }
}

.shareButton {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.waShare {
  display: inline-block;
  font-weight: 700 !important;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  font-size: 14px !important;
  background-color: #25d366 !important;
  color: white !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  border-radius: 4px;
  height: 40px;
  margin-right: 8px;
}

.fbShare {
  display: inline-block;
  font-weight: 700 !important;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  font-size: 14px !important;
  background-color: #3B5998 !important;
  color: white !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  transition: all 0.35s ease 0s;
  text-decoration: none;
  border-radius: 4px;
  height: 40px;
  margin-left: 8px;
}

.back{
  padding: 8px 0 16px;
  &__btn {
    width: 100%;
    border: 1px solid #00AFEF;
    padding: 10px;
    color: #00AFEF;
    font-size: 16px;
    background: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }
}
